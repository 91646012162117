<template>
	<table-page
		v-show="isLoaded"
		v-if="columns.length"
		:tableName="$options.name"
		:columns="columns"
		:filterModel="filterModel"
		headerTitle="sectionsintheme3558"
		tooltipMsg="pimanualofsecti"
		:requestFunction="requestFunction"
		:defaultOrderBy="{ column: 'Order', ascending: 1 }"
		modelId="Id"
		@addNewRecord="redirectToDetail()"
		@editRecord="redirectToDetail($event)"
		@filterChanged="handleFilter($event)"
		@removeFilters="removeAllFilters($event)"
	>
		<template #CsectionId="list">
			{{ csectionIdTexts[list.row.CsectionId] }}
		</template>

		<template #MtypeId="list">
			{{ mtypeIdTexts[list.row.MtypeId] }}
		</template>
	</table-page>
</template>

<script>
import TablePage from '@/components/general/table-page';
import { tablePageMixin } from '@/components/general/table-page.mixin';
import * as links from '@/router/links';
import http from '@/services/helpers/http';
import serviceEnums from '@/services/service/enums.service';
import { setDropdownOptions, getColumnDropdownOptionsAsDict } from '@/components/general/utils';

import { tableColumns } from './section-menu.table-data';

export default {
	name: 'SectionMenuTable',

	components: {
		TablePage,
	},

	mixins: [tablePageMixin],

	data() {
		return {
			controllerName: 'SectionMenuListItem',
		};
	},

	computed: {
		csectionIdTexts() {
			return getColumnDropdownOptionsAsDict(this.columns, 'CsectionId');
		},

		mtypeIdTexts() {
			return getColumnDropdownOptionsAsDict(this.columns, 'MtypeId');
		},
	},

	async created() {
		this.columns = await this.prepareColumns(tableColumns);
	},

	methods: {
		async prepareColumns() {
			const [catalogSections] = await Promise.all([http.get('CatalogSection/GetAllVisible').then((res) => res.data)]);

			let tblCols = prepareCsectionId(tableColumns);
			tblCols = prepareMtypeId(tableColumns);

			return tblCols;

			function prepareCsectionId(columns) {
				const values = catalogSections.map((cs) => ({ text: cs.CsectionNameSk, value: cs.CsectionId }));
				values.unshift({ text: '', value: null });

				return setDropdownOptions(columns, 'CsectionId', values);
			}

			function prepareMtypeId(columns) {
				const values = serviceEnums.getEnumForDropdown('sectionMenuType', true);

				return setDropdownOptions(columns, 'MtypeId', values);
			}
		},

		redirectToDetail(id = 0) {
			this.$router.push({
				name: links.sectionMenuDetailName,
				params: { id },
			});
		},
	},
};
</script>