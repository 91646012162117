export const tableColumns = [
	{
		model: 'Id',
		i18n: 'id2541',
		sortable: true,
		filter: 'text',
		filterOptions: {
			props: {
				type: 'number',
			},
		},
	},
	{
		model: 'NameSk',
		i18n: 'namesk',
		sortable: true,
		filter: 'text',
	},
	{
		model: 'NameCz',
		i18n: 'namecz',
		sortable: true,
		filter: 'text',
	},
	{
		model: 'NamePl',
		i18n: 'namepl',
		hidden: true,
		sortable: true,
		filter: 'text',
	},
	{
		model: 'NameEn',
		i18n: 'nameen',
		hidden: true,
		sortable: true,
		filter: 'text',
	},
	{
		model: 'NameDe',
		i18n: 'namede',
		hidden: true,
		sortable: true,
		filter: 'text',
	},
	{
		model: 'NameHu',
		i18n: 'namehu',
		hidden: true,
		sortable: true,
		filter: 'text',
	},
	{
		model: 'MtypeId',
		i18n: 'typeofurl',
		sortable: true,
		filter: 'dropdown',
		filterOptions: {
			dropdownOptions: [],
		},
	},
	{
		model: 'Order',
		i18n: 'order',
		sortable: true,
		filter: 'text',
		filterOptions: {
			props: {
				type: 'number',
			},
		},
	},
	{
		model: 'CsectionId',
		i18n: 'catalogsection',
		sortable: true,
		filterInitValue: 1, // Chaty
		filter: 'dropdown',
		filterOptions: {
			dropdownOptions: [],
		},
	},
	{
		colType: 'country',
		model: 'CountryId',
		i18n: 'country',
		sortable: true,
	},
	{
		colType: 'boolean',
		model: 'IsActive',
		i18n: 'active',
		sortable: true,
	},
];
